import React, { useState } from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"
import ReactMarkdown from "react-markdown"

import {
  Box,
  Button,
  Column,
  Container,
  Flex,
  H1,
  H2,
  H3,
  Section,
  Text,
} from "components"

import { Earth } from "vectors"

const FlipFlop = ({ title, subtitle, graphic, side }) => (
  <Box my={[5, 6]}>
    <Flex flexWrap="wrap" alignItems="center" m={-4}>
      <Flex
        justifyContent="center"
        alignItems="center"
        width={["100%", 1 / 2]}
        p={4}
      >
        {graphic}
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        order={["initial", side === "left" ? 1 : -1]}
        width={["100%", 1 / 2]}
        p={4}
      >
        <Box maxWidth={387}>
          <H2 children={title} />
          <Text children={subtitle} mt={3} fontSize={[3, 4]} lineHeight={1.5} />
        </Box>
      </Flex>
    </Flex>
  </Box>
)

const Leader = ({ heading, content, image }) => (
  <Box width={["100%", 1 / 2]} p={3}>
    <Box width={["75%", "90%"]} pt={[2, 0]}>
      <Box
        pb="100%"
        bg="primary"
        css={css({
          borderRadius: 9999,
          background: image && "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        })}
      />
    </Box>
    <H3 children={heading} mt={4} textAlign="center" />
    <Text mt={3} textAlign="center" lineHeight={1.5}>
      {content}
    </Text>
  </Box>
)

function AboutPage() {
  const [currentPosition, setCurrentPosition] = useState(0)
  const [size] = useState(6)

  return (
    <StaticQuery
      query={graphql`
        {
          page: datoCmsAbout {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            seoKeywords
            heroImage {
              url
            }
            heroTitle
            heroSubtitle
            heroDescription
            whyTitle
            whySubtitle
            headquartersImageVideo {
              url
            }
            headquartersTitle
            headquartersSubtitle
            headquartersPanorama {
              link
            }
            foundersTitle
            foundersSubtitle
            founders {
              image {
                url
              }
              name
              description
            }
            careersTitle
            careersSubtitle
            careersButtonText
            nameTitle
            nameSubtitle
            nameDescription
            newsTitle
            newsSubtitle
          }
        }
      `}
      render={data => (
        <>
          <HelmetDatoCms seo={data.page.seoMetaTags}>
            <meta name="keywords" content={data.page.seoKeywords} />
          </HelmetDatoCms>
          <Section
            image={
              data.page.heroImage &&
              data.page.heroImage.url &&
              data.page.heroImage.url.indexOf(".mp4") === -1
                ? data.page.heroImage.url
                : ""
            }
            video={
              data.page.heroImage &&
              data.page.heroImage.url &&
              data.page.heroImage.url.indexOf(".mp4") !== -1
                ? data.page.heroImage.url
                : undefined
            }
            bg="primary"
            top={170}
          />
          <Box bg="primary" py={[5, 6]}>
            <Container>
              <Column left>
                <H1 children={data.page.heroTitle} color="textReverse" />
                <Text
                  children={data.page.heroSubtitle}
                  mt={[3, 4]}
                  fontFamily="condensed"
                  fontSize={[5, 6]}
                />
                <Box
                  mt={[3, 4]}
                  dangerouslySetInnerHTML={{
                    __html: data.page.heroDescription,
                  }}
                />
              </Column>
            </Container>
          </Box>
          <Box>
            <Container maxWidth={1200} py={5}>
              <FlipFlop
                graphic={Earth}
                title={data.page.whyTitle}
                subtitle={data.page.whySubtitle}
                side="left"
              />
            </Container>
          </Box>
          <Section
            image={
              data.page.headquartersImageVideo &&
              data.page.headquartersImageVideo.url &&
              data.page.headquartersImageVideo.url.indexOf(".mp4") === -1
                ? data.page.headquartersImageVideo.url
                : ""
            }
            video={
              data.page.headquartersImageVideo &&
              data.page.headquartersImageVideo.url &&
              data.page.headquartersImageVideo.url.indexOf(".mp4") !== -1
                ? data.page.headquartersImageVideo.url
                : undefined
            }
            title={data.page.headquartersTitle}
            subtitle={data.page.headquartersSubtitle}
            content={
              data.page.headquartersPanorama &&
              data.page.headquartersPanorama.link && (
                <a
                  href={data.page.headquartersPanorama.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    width={50}
                    height={50}
                    fill="none"
                    css={css({ verticalAlign: "top" })}
                  >
                    <path
                      d="M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25z"
                      fill="#FCD06B"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M25.874 33.763l-5.695 5.694-1.414-1.414 3.996-3.996c-4.305-.18-8.194-.84-11.168-1.832-1.755-.585-3.248-1.303-4.322-2.15-1.063-.837-1.854-1.917-1.854-3.218 0-1.3.791-2.38 1.854-3.218.734-.578 1.664-1.097 2.74-1.554.114.674.322 1.317.611 1.914-.874.384-1.583.793-2.113 1.211-.835.658-1.093 1.222-1.093 1.647 0 .425.258.99 1.093 1.647.824.65 2.077 1.278 3.716 1.824 2.739.913 6.399 1.546 10.516 1.726l-3.976-3.976 1.414-1.414 5.695 5.694.707.708-.707.707zM13.92 21.139c.051.602.263 1.147.595 1.591a35.81 35.81 0 012.053-.423 1.187 1.187 0 01-.469-.284 1.454 1.454 0 01-.41-.972h-1.776l.002.022.005.066zm5.901.72c-.734.075-1.447.164-2.137.267.311-.256.485-.677.51-1.1l.001-.023.001-.02.001-.058v-.006a1.682 1.682 0 00-.23-.854 48.8 48.8 0 011.737-.203 3.139 3.139 0 01.212.925v.009l.003.039a2.987 2.987 0 010 .392 3.173 3.173 0 01-.098.633zm1.522-1.319c.024-.278.079-.554.158-.825a56.02 56.02 0 011.942-.092 1.914 1.914 0 00-.38 1.404c.027.208.09.414.184.603a53.39 53.39 0 00-1.797.092 3.225 3.225 0 01-.12-.887l.001-.048c.001-.083.005-.165.012-.247zm6.275-.535a3.38 3.38 0 00-.095-.36 57.282 57.282 0 00-2.018-.047c.105.13.191.276.257.433a1.98 1.98 0 01.153.756 1.937 1.937 0 01-.164.773l-.001.001a1.924 1.924 0 01-.018.04c.622.005 1.235.022 1.838.048a3.543 3.543 0 00.12-.934 3.5 3.5 0 00-.072-.71zm1.396.71a7.524 7.524 0 01-.147-.998c.592.039 1.175.087 1.748.143.029.27.074.564.143.855.109.463.276.915.525 1.23a49.27 49.27 0 00-1.953-.19 6.35 6.35 0 01-.316-1.04zm6.109.142c.025-.105.047-.21.067-.314a40.792 40.792 0 00-1.683-.308c-.112.719-.345 1.514-.798 1.9.615.092 1.212.195 1.788.309a5.606 5.606 0 00.626-1.587zm3.846 1.107c.273-.02.54-.058.801-.114 1.311.51 2.435 1.104 3.292 1.78 1.063.837 1.855 1.917 1.855 3.217 0 1.819-1.522 3.206-3.369 4.19-1.927 1.029-4.6 1.838-7.693 2.366l-.336-1.972c2.977-.508 5.419-1.268 7.088-2.158 1.751-.934 2.31-1.814 2.31-2.426 0-.425-.258-.989-1.093-1.647-.766-.604-1.904-1.19-3.377-1.707.22-.505.392-1.02.522-1.53z"
                      fill="#fff"
                    />
                    <path
                      d="M15.93 16.983c.023-.552.36-1.14.983-1.14.66 0 1.032.552 1.032 1.152 0 .756-.396 1.248-1.152 1.248h-.192v1.26l.324-.024c.792-.06 1.272.72 1.272 1.44 0 .708-.42 1.452-1.212 1.452-.78 0-1.26-.6-1.296-1.32h-1.776c.096 1.62 1.344 2.856 3 2.856 1.728 0 3.012-1.104 3.012-2.88 0-.828-.336-1.848-1.164-2.208.612-.408.864-1.188.864-1.884 0-1.56-1.044-2.556-2.604-2.556-1.62 0-2.616 1.044-2.772 2.604h1.68zM24.835 14.247l-2.4 3.564c-.588.876-1.104 1.944-1.104 3.024 0 1.812 1.38 3.072 3.168 3.072 1.86 0 3.192-1.332 3.192-3.192 0-1.56-1.032-3-2.688-3-.252 0-.504.036-.708.12l-.024-.024 1.86-2.628-1.296-.936zm-.348 8.22c-.888 0-1.44-.876-1.44-1.68 0-.816.552-1.692 1.44-1.692.888 0 1.428.876 1.428 1.692 0 .804-.54 1.68-1.428 1.68zM32.084 14.379c-2.424 0-3.24 2.832-3.24 4.776 0 1.932.84 4.752 3.24 4.752 2.4 0 3.24-2.82 3.24-4.752 0-1.944-.816-4.776-3.24-4.776zm0 1.536c1.26 0 1.512 2.304 1.512 3.216 0 .9-.252 3.24-1.512 3.24-1.248 0-1.512-2.34-1.512-3.24 0-.912.264-3.216 1.512-3.216zM40.354 16.179c0-.984-.816-1.8-1.8-1.8-.996 0-1.812.816-1.8 1.8 0 .996.804 1.8 1.8 1.8.984 0 1.8-.804 1.8-1.8zm-1.812-.984c.552 0 .996.444.996.984a.97.97 0 01-.972.984.991.991 0 01-.996-.984.97.97 0 01.972-.984z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              )
            }
            bg="backgroundReverse"
            color="textReverse"
          />
          <Box bg="background" py={[5, 6]}>
            <Container maxWidth={660}>
              {data.page.foundersTitle && (
                <H2
                  children={data.page.foundersTitle}
                  textAlign="center"
                  color="primary"
                />
              )}
              {data.page.foundersSubtitle && (
                <Text
                  children={data.page.foundersSubtitle}
                  mt={[3, 4]}
                  fontFamily="condensed"
                  fontSize={[5, 6]}
                  textAlign="center"
                />
              )}
              <Box mt={5}>
                <Flex flexWrap="wrap" m={-3}>
                  {data.page.founders.map(founder => (
                    <Leader
                      image={founder.image && founder.image.url}
                      heading={founder.name}
                      content={founder.description}
                      key={"founder" + founder.name}
                    />
                  ))}
                </Flex>
              </Box>
            </Container>
          </Box>
          {data.page.careersTitle && (
            <Box bg="primary" py={[5, 6]}>
              <Container maxWidth={580}>
                <H2 children={data.page.careersTitle} textAlign="center" />
                {data.page.careersSubtitle && (
                  <Text
                    children={data.page.careersSubtitle}
                    mt={[3, 4]}
                    fontFamily="condensed"
                    fontSize={[5, 6]}
                    textAlign="center"
                  />
                )}
                {data.page.careersButtonText && (
                  <Flex justifyContent="center" mt={[4, 5]}>
                    <Button
                      children={data.page.careersButtonText}
                      as={"a"}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.datocms-assets.com/14139/1717165341-falkbuilt-ltd-bills-21-report_for_government_signed_public_version.pdf"
                      variant="default"
                    />
                  </Flex>
                )}
              </Container>
            </Box>
          )}
          <Box bg="backgroundReverse" py={[5, 6]}>
            <Container>
              <Flex flexWrap="wrap" alignItems="center" m={[-3, -4]}>
                <Box width={["100%", null, 1 / 2]} p={[3, 4]}>
                  <H1 children={data.page.nameTitle} color="textReverse" />
                </Box>
                <Box width={["100%", null, 1 / 2]} p={[3, 4]}>
                  <Text
                    children={data.page.nameSubtitle}
                    mt={[3, 4]}
                    fontFamily="condensed"
                    fontSize={[5, 6]}
                    color="textReverse"
                  />
                  <Text
                    mt={3}
                    lineHeight={1.5}
                    color="textReverse"
                    css={css({
                      strong: {
                        px: "4px",
                        fontWeight: 600,
                        bg: "primary",
                        color: "text",
                      },
                    })}
                  >
                    <ReactMarkdown source={data.page.nameDescription} />
                  </Text>
                </Box>
              </Flex>
            </Container>
          </Box>
          {/*<Box bg="muted" py={[5, 6]} position="relative">
            <span
              id="blog"
              name="anchor"
              css={css({
                content: "''",
                position: "absolute",
                left: "0px",
                top: "-90px",
                pointerEvents: "none",
              })}
            >
              {" "}
            </span>
            <Container>
              <Box width="100%" maxWidth={630} mx="auto">
                <H1 as="h2" children={"Blog"} textAlign="center" />
                <Text
                  children={
                    "Keep up to date with all things Falk in our blog posts below."
                  }
                  mt={[3, 4]}
                  fontFamily="condensed"
                  fontSize={[5, 6]}
                  textAlign="center"
                />
              </Box>
              <Flex
                pt={5}
                m={-3}
                flexWrap="wrap"
                css={css({ overflow: "hidden" })}
              >
                {data.posts.nodes &&
                  data.posts.nodes.length > 0 &&
                  data.posts.nodes
                    .slice(0, size * (currentPosition + 1))
                    .map((post, index) => (
                      <PostCard
                        image={post.image && post.image.url}
                        date={
                          post.date && moment(post.date).format("MMM DD, YYYY")
                        }
                        title={post.title}
                        subtitle={post.subtitle}
                        link={"/blog/" + post.url + "/"}
                        key={"blogPost" + post.url}
                      />
                    ))}
              </Flex>
              {data.posts.nodes &&
                data.posts.nodes.length > size * (currentPosition + 1) && (
                  <Flex justifyContent="center" mt={5}>
                    <Button
                      children={"See more"}
                      onClick={() => {
                        setCurrentPosition(currentPosition + 1)
                      }}
                    />
                  </Flex>
                )}
            </Container> 
          </Box>*/}
        </>
      )}
    />
  )
}

export default AboutPage
